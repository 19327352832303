import React, { Component } from 'react';
import LoginForm from '../Login/LoginForm';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import ExternalLogin from '../Login/ExternalLogin';

class Login extends Component {

  render() {
    return (
    <div style={{ paddingTop: 50, paddingLeft: 10, paddingRight: 10}}>
        <LoginForm />
        <Button component={Link} to="/register" fullWidth variant="outlined" color="secondary"  style={{marginBottom: "20px"}}> 
          Registrar
        </Button>
        <Link to="/forgot_password" style={{color: "#01bf9b"}}>
         <Typography color="secondary" variant="body">
          Esquece a senha?
         </Typography>
         </Link>
        <ExternalLogin />
    </div>
    )
  }
}

export default Login;