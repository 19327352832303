import { base_url } from "./Base";

export const update_profile = async (userid, body) => {
    return await fetch(`${base_url}company_user/${userid}` , {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "PUT",
        body: JSON.stringify(body)
    })
}