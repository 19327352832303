import { base_url } from "./Base";

export const getCustomers = async (companyid,cellphone) => {
    return await fetch(`${base_url}company/${companyid}/customer?cellphone=${cellphone}`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"GET"
    });
}

export const createCustomer = async (companyid,body) => {
    return await fetch(`${base_url}company/${companyid}/customer`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"POST",
        body: JSON.stringify(body)
    });
}