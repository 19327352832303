import { base_url } from "./Base";

export const get_scheduling_items = async (scheduleid,date) => {
    return await fetch(`${base_url}schedule/${scheduleid}/scheduling_item?date=${date}`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    })
}

export const scheduling_item = (scheduleid, customer, date, serviceid, fixed_reserve) => {
    return fetch(`${base_url}schedule/${scheduleid}/scheduling_item`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
       method: "POST",
       body: JSON.stringify({
         customer_id: customer,
         schedulings: {
           start: date,
           service: serviceid
         },
         fixed_reserve
       })
     });
 } 
 
 export const cancel_schedule = async (id) => {
    return await fetch(`${base_url}scheduling_item/${id}/cancel`,{
      headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json",
        "Authorization" : localStorage.getItem("worker_app_token")        
      },
      method: "PATCH"
    });
  }