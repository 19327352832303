import React, { PureComponent } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';


export default class SelectInterval extends PureComponent {

    state = {
        start: this.props.start,
        end: this.props.end,
        times: this.props.times
    }

    setTimes = (time) => (e) => {
        this.setState({
            [time]: e.target.value
        }, () => {
            let times = {
                start: this.state.start,
                end: this.state.end
            }
            this.props.change(times);
        })
    }

    render() {
        return (
            <Grid container spacing={8}>
               <Grid item xs={3}>
                <FormControl style={{width:"100px"}}>
                        <InputLabel htmlFor="start">Horário inicial</InputLabel>
                        <Select
                            value={this.state.start}
                            onChange={this.setTimes('start')}
                            inputProps={{
                            name: 'Início',
                            id: 'start',
                            }}
                        >
                            
                            {this.state.times.map((number,index) => <MenuItem key={index} value={number}>{number}</MenuItem>)}
                        </Select>
                    </FormControl>
               </Grid>
               <Grid item xs={2}></Grid>
               <Grid item xs={3}>
                <FormControl style={{width:"100px"}}>
                        <InputLabel htmlFor="end">Horário final</InputLabel>
                        <Select
                            value={this.state.end}
                            onChange={this.setTimes('end')}
                            inputProps={{
                            name: 'Fim',
                            id: 'end',
                            }}
                        >
                        {this.state.times.map((number,index) => <MenuItem key={index} value={number}>{number}</MenuItem>)}
    
                        </Select>
                    </FormControl>
               </Grid>
               <Grid item xs={4} style={{textAlign: "right", marginTop:"10px"}}>
                    {this.props.children}
               </Grid>
            </Grid>
        )
      }
}
