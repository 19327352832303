import React, { Component } from 'react';
import { Grid, Paper, Typography, Fade, Icon } from '@material-ui/core';
import { verify } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom'; 


export default class Verify extends Component {
  state = {
      message: '',
      success: '',
      fade: false,
      redirect: false
  }

  constructor(props) {
    super(props);
    this.token = props.match.params.token;
  }

   async componentDidMount() {
       const response = await verify(this.token);
       
       if (response.status === 200) {
           const { message, success } = await response.json();
           const fade = !this.state.fade;
           const redirect = !this.state.redirect;
           
            this.setState({message, success, fade});
            setTimeout(() => { this.setState({redirect})}, 3000);
       }
   }

  render() {
    if (this.state.redirect) return <Redirect to="/" />
    return (
        <Grid>
            <Fade in={this.state.fade}>
                <Paper
                    style={{padding:20, margin:20}}
                >
                    <Grid container direction="row" alignItems="center">
                            <Grid item xs={3}>
                                <Typography style={{fontSize: 1}}>
                                    {this.state.success === 'OK' ? <Icon style={{color: '#28a745'}}>check_circle</Icon> 
                                    : <Icon style={{color: '#dc3545'}}>error</Icon>}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>
                                    {this.state.message}
                                </Typography>
                            </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Grid>
    )
  }
}
