import { base_url } from "./Base";

export const getLock = async (scheduleid,date) => {

    return await fetch(`${base_url}schedule/${scheduleid}/calendar_lock?date=${date}`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"GET"
    });
}

export const createLock = async (scheduleid,body) => {

    return await fetch(`${base_url}schedule/${scheduleid}/calendar_lock`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"POST",
        body: JSON.stringify(body)
    })
}

export const deleteLock = async (scheduleid,time,body) => {

    return await fetch(`${base_url}schedule/${scheduleid}/calendar_lock/${time}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"DELETE",
        body: JSON.stringify(body)
    });
}