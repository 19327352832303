import React, { Component } from 'react';
import { ScheduleEdit } from '../ScheduleConfig/Edit/ScheduleEdit';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class EditSchedule extends Component {
  render() {
    let { id } = this.props.match.params;
    if(hasPermission()) return <Redirect to="/" />
    const header_buttons = [
        {
            type: "menu", items: [
              {title: "Serviços", link: `/schedule/${id}/services`},
              {title: "Horários", link: `/schedule/${id}/working_edit`},
              {title: "Bloqueios", link: `/schedule/${id}/lock`},
              {title: "Desbloqueios", link: `/schedule/${id}/unlock`},
              {title: "Horários Fixos", link: `/schedule/${id}/fixed_reserves`}
            ]
        }
    ]
    return (
        <div>
            <Header buttons={header_buttons} title="Editar agenda" backbutton={this.props.history} />
            <ScheduleEdit {...this.props} />
        </div>
    )
  }
}
