import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import { Validator } from '../../Helpers/Validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { update_company } from '../../Services/Company';
import MaskedInput from 'react-text-mask';
import { Grid } from '@material-ui/core';
import UploadThumb from '../UploadThumb/UploadThumb';

const cpf = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

const cnpj = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/ , '.',/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
    />
    )
}

const phone = (props) => {
    const { inputRef , ...other} = props;
  
    return (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/, /\d/,')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/ ]}
        placeholderChar={'\u2000'}
    />
    )
}

export default class Company extends Component {

  constructor(props) {
      super(props);

      let { fantasy_name, type, phone, logo } = JSON.parse(localStorage.getItem('current_company'));
      
      let company_type = JSON.parse(localStorage.getItem('company_type'));
        
      this.state = {
          logo_apresentation: logo,
          logo,
          fantasy_name,
          phone: phone || '',
          type,
          cpf: company_type.cpf || '',
          cnpj: company_type.cnpj || '',
          name: company_type.name || '',
          trade: company_type.trade || '',
          current_trade: company_type.trade || '',
          current_name: company_type.name || '',
          current_cpf: company_type.cpf || '',
          current_cnpj: company_type.cnpj || '',
          current_fantasy_name: fantasy_name,
          current_phone: phone,
          current_type: type,
          current_logo: logo || null
      } 
      this.validator = new Validator();

  }
  
  saveState = (name) => (e) => {
    let value = e.target.value;
    if(name === "cnpj" || name === "cpf" || name === "phone") {
        value = value.replace(/[\s.\-()/]/g, "");
    }
    this.setState({
        [name]:value
    });

  }

  isChanged = () => {
      let { 
          fantasy_name,  current_fantasy_name, 
          cnpj, current_cnpj,
          cpf, current_cpf,
          name, current_name,
          type, current_type,
          phone, current_phone,
          trade, current_trade,
          logo, current_logo
      } = this.state;
      return fantasy_name !== current_fantasy_name || cnpj !== current_cnpj || cpf !== current_cpf 
            || name !== current_name || type !== current_type || phone !== current_phone ||
            trade !== current_trade || logo !== current_logo;
  }

  isValid = () => {
    return this.validator.check();
  }

  submit = async (e) => {
    e.preventDefault();
    
    let { fantasy_name , cnpj , trade, name, cpf , type, phone, logo } = this.state;
    let data;
    if(type === 'P') {
        data = {
            fantasy_name,
            cpf,
            name,
            type,
            phone, 
            logo
        }
    } else if(type === 'C') {
        data = {
            fantasy_name,
            cnpj,
            trade,
            type,
            phone,
            logo
        }
    }

    let response_company = await update_company(data);
    if(response_company.status === 200) {
        let json = await response_company.json();
        localStorage.setItem('current_company', JSON.stringify(json.company));
        localStorage.setItem('company_type', JSON.stringify(json.company_type));
    }
    
  }

  componentDidMount() {
  }

  changeImage = (base64) => {
    this.setState({
        logo: base64,
        logo_apresentation: base64
    })
  }

  render() {
    if(this.state.type === 'P') {
        this.validator.setValues([
            { value:this.state.name, min: 7, required:true },
            { value:this.state.cpf, min: 11, max: 11, required:true },
            { value:this.state.fantasy_name, min: 7, required: true},
            { value:this.state.phone, min: 10, required: true},
        ])
    } else if(this.state.type === 'C') {
        this.validator.setValues([
            { value:this.state.trade, min: 7, required:true },
            { value:this.state.cnpj.trim(), min: 14, max: 14, required:true },
            { value:this.state.fantasy_name, min: 7, required: true},
            { value:this.state.phone, min: 10, required: true},
        ])
    }

    return (
        <form style={{margin: 10}} onSubmit={this.submit} >
         <Grid container >
                <UploadThumb logo={this.state.logo_apresentation} changeImage={this.changeImage} />
                <Grid item xs={12} style={{margin:10}}>
               
                    <Paper elevation={1} style={{padding:4}}>
                    <Typography variant="subtitle1">
                        Tipo de cadastro:
                    </Typography>
                    <Grid container justify="center">
                        <FormControl component="fieldset" style={{justifyContent: 'center'}}>
                            <RadioGroup
                                row
                                name="company_type"
                                onChange={this.saveState('type')}
                                value={this.state.type}
                            >
                                <FormControlLabel labelPlacement="bottom" value="P" control={<Radio />} label="Pessoa Física" />
                                <FormControlLabel labelPlacement="bottom" value="C" control={<Radio />} label="Pessoa Jurídica" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{margin: 10}}>
                    <Paper elevation={1} style={{padding:10}}>
                        { this.state.type === 'C' ?
                            <TextField
                                id="trade"
                                label="Razão Social"
                                margin="dense"
                                fullWidth
                                autoComplete="off"
                                onChange={this.saveState('trade')}
                                defaultValue={this.state.trade}
                             /> : this.state.type === 'P' ? null : null
                        }
                        <TextField
                            id="fantasy_name"
                            label="Nome Fantasia"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            onChange={this.saveState('fantasy_name')}
                            helperText="Nome apresentado para os usuários"
                            defaultValue={this.state.fantasy_name}
                        />
                        { this.state.type === 'C' ?
                            <TextField
                                id="Cnpj"
                                label="Cnpj"
                                margin="dense"
                                fullWidth
                                onChange={this.saveState('cnpj')}
                                autoComplete="off"
                                defaultValue={this.state.cnpj}
                                InputProps={{
                                    inputComponent: cnpj
                                }}
                            /> : this.state.type === 'P' ? null : null
                        }
                        { this.state.type === 'P' ?
                            <TextField
                                id="Cpf"
                                label="Cpf"
                                margin="dense"
                                fullWidth
                                autoComplete="off"
                                onChange={this.saveState('cpf')}
                                defaultValue={this.state.cpf}
                                InputProps={{
                                    inputComponent: cpf
                                }}

                            /> : this.state.type === 'C' ? null : null
                        }
                        { this.state.type === 'P' ?
                             <TextField
                                id="person_name"
                                label="Nome Titular"
                                margin="dense"
                                fullWidth
                                autoComplete="off"
                                onChange={this.saveState('name')}
                                defaultValue={this.state.name}
                             /> : this.state.type === 'C' ? null : null
                        }
                        <TextField
                            id="phone"
                            label="Telefone"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            onChange={this.saveState('phone')}
                            defaultValue={this.state.phone}
                            InputProps={{
                                inputComponent: phone
                            }}
                        />
                    </Paper>
                    
                    <Button disabled={ !this.isValid() || !this.isChanged()} type="submit" style={{marginTop: 10}} fullWidth variant="contained" size="medium" color="secondary">
                        salvar alterações
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
  }
}
