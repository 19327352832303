import { base_url } from "./Base";

export const createWorkingTimes = (id, data) => {
    return fetch(`${base_url}schedule/${id}/working_time`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "POST",
        body: JSON.stringify({
            working_times: data
        })
    })
}

export const getWorkingTimes = async (scheduleid) => {
    return await fetch(`${base_url}schedule/${scheduleid}/working_time`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    })
}

export const updateWorkingTime = async (scheduleid, workingid, body) => {
    return await fetch(`${base_url}schedule/${scheduleid}/working_time/${workingid}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export const deleteWorkingTime = async (scheduleid, workingid) => {
    return await fetch(`${base_url}schedule/${scheduleid}/working_time/${workingid}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "DELETE",
    })
}