import React, { Component } from 'react';
import Bar from '../Bar/Bar';
import { ScheduleWorkingEdit } from '../ScheduleConfig/Edit/ScheduleWorkingEdit';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

import BackButton  from '../BackButton/BackButton';

import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

class Header extends Component {
  
    render() {
        return (
            <Toolbar>
                <BackButton {...this.props} />
                <Typography style={{flexGrow:1}} variant="subtitle1" color="inherit" noWrap> 
                      {this.props.title}
                </Typography>
            
            </Toolbar>
  
        )
    }
  }

export default class WorkingTime extends Component {
  render() {
    if(hasPermission()) return <Redirect to="/" />
    return (
        <div>
            <Bar>
            <Header title="Horários" history={this.props.history} />
                
            </Bar>
            <ScheduleWorkingEdit {...this.props} />
        </div>
    )
  }
}
