import React, { PureComponent } from 'react';

import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';


import { getServices, updateService, deleteService, createServices } from '../../../Services/Services';
import { get_schedule } from '../../../Services/ScheduleConfig';

class EditScheduleService extends PureComponent {
    
    
    constructor(props) {
        super(props);

        this.state = {
            service_name: this.props.service_name,
            multiplier: this.props.multiplier,
            price: this.props.price,
            time: this.props.time,
            id: this.props.id,
            seid: this.props.seid
        }
        this.interval = setTimeout;
    }

    serviceTime = () => {
        let { time, multiplier } = this.state;
        let date = new Date();
        let explode_time = time.split(":");
        date.setHours(parseInt(explode_time[0]) * multiplier);
        date.setMinutes(parseInt(explode_time[1]) * multiplier);
        date.setSeconds(parseInt(explode_time[2]) * multiplier);
        return date.toLocaleTimeString().slice(0,5);
    }

    handleChange = (name) => async (e) => {
        let value = e.target.value ? e.target.value : "0";
        clearTimeout(this.interval);
        this.setState({
            [name]: value
        })
        let { id, seid } = this.state;
        let data = { [name]:value };
        let response = await updateService(seid,id,data);
        await response.json();

        if(response.status === 200) {
            let service = {
                id: this.state.id,
                service_name: this.state.service_name,
                multiplier: this.state.multiplier,
                price: this.state.price
            }
            this.props.update(service);
        }
    }

    render () {
        return (
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Typography variant="caption">
                       Tempo: {this.serviceTime()}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                <TextField
                    id="service_name"
                    label="Nome"
                    type="text"
                    onBlur={this.handleChange('service_name')}
                    margin="normal"
                    fullWidth
                    defaultValue={this.state.service_name}
                />
                </Grid>
                <Grid item xs={3} >
                <TextField
                    id="price"
                    label="Preço"
                    type="number"
                    onBlur={this.handleChange('price')}
                    margin="normal"
                    defaultValue={this.state.price}
                />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={7} >
                    <TextField
                        id="price"
                        label="Multiplicador"
                        type="number"
                        defaultValue={this.state.multiplier}
                        onBlur={this.handleChange('multiplier')}
                        margin="normal"
                        helperText="Multiplica o tempo do serviço"

                    />
                </Grid>
            </Grid>
        )
    }
}

class CreateScheduleService extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            multiplier: "",
            service_name: "",
            price: ""
        }
    }

    resetState = () => {
        this.setState({
            service_name: "",
            multiplier: "",
            price: ""
        })
    }

    handleClose = () => {
        this.props.close();
        this.resetState();
    }

    changeData = (name) => (e) => {
        this.setState({
            [name]: e.target.value
        })
    }

    createServiceSchedule = async () => {
        const { seid } = this.props;
        let { price, multiplier, service_name } = this.state;
        let body = {service_name, price, multiplier};
        let response = await createServices(seid,body);
        await response.json();
        this.props.update();
        this.resetState();
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Novo serviço</DialogTitle>
                <DialogContent>
                <TextField
                        margin="dense"
                        id="service_name"
                        label="Nome do serviço"
                        onChange={this.changeData('service_name')}
                        type="text"
                        fullWidth

                    />
                
                    <TextField
                        margin="dense"
                        id="price"
                        label="Preço"
                        onChange={this.changeData('price')}
                        type="number"
                        fullWidth

                    />
                    <TextField
                        margin="dense"
                        id="multiplier"
                        label="Multilicador"
                        onChange={this.changeData('multiplier')}
                        type="number"
                        fullWidth
                        
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={this.createServiceSchedule} color="primary">
                    Criar
                </Button>
                </DialogActions>
          </Dialog>
        )
    }
}

export class ScheduleServices extends PureComponent {
  
    constructor(props) {
        super(props);
        this.seid = this.props.match.params.id;
        this.state = {
            services: [],
            services_not_registered: [],
            ready: 0,
            time: "",
            open: false
        }      
  }

  async componentDidMount() {
    const { seid } = this;
    let response_services = await getServices(seid);
    let services = await response_services.json();
    let response_schedule = await get_schedule(seid);
    let { time } = await response_schedule.json();

    let query = '?not_registered=true'
    let services_not_registered = await getServices(seid,query);
        services_not_registered = await services_not_registered.json();
    this.setState({
        services,
        time,
        services_not_registered
    });
  }

  delete = (id) => async () => {
    const {seid} = this;
    let services = this.state.services.filter(service => {
        if(service.id !== id) return service;

        return null;
    })

    let response = await deleteService(seid, id);
    await response.json();
    let query = '?not_registered=true'
    let services_not_registered = await getServices(seid,query);
        services_not_registered = await services_not_registered.json();

    this.setState({
        services,
        services_not_registered
    });

  }

  toggleModal = () => {
    this.props.modal();
  }

  updateServiceNotRegistered = async () => {
    let query = '?not_registered=true'
    let services_not_registered = await getServices(this.seid,query);
        services_not_registered = await services_not_registered.json();

        let response_services = await getServices(this.seid);
        let services = await response_services.json();

        this.setState({
            services,
            services_not_registered
        })
        this.props.modal();

  }

  updateService = (service) => {
    this.setState({
        services: this.state.services.map(current_service => {
            return current_service.id === service.id ? service : current_service
         })
    })
  }

  render() {
    return (
        <Grid item xs={12} style={{margin:10}}>
           {this.state.services.map(service => {
               return (
                <ExpansionPanel key={service.id}>
                    <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                     <Typography >{service.service_name}</Typography>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <EditScheduleService
                                multiplier={service.multiplier}
                                price={service.price}
                                time={this.state.time}
                                id={service.id}
                                service_name={service.service_name}
                                seid={this.seid}
                                update={this.updateService}
                            />
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button onClick={this.delete(service.id)} size="small" color="secondary">
                                Deletar
                            </Button>
                        </ExpansionPanelActions>
                </ExpansionPanel>
               )
           })}
           <CreateScheduleService 
                open={this.props.open} 
                close={this.toggleModal}
                update={this.updateServiceNotRegistered}
                seid={this.seid}
                services={this.state.services_not_registered}
           />
        </Grid>
    );
  }
}
