
export const viacep = async (cep) => {

    return await fetch(`https://viacep.com.br/ws/${cep}/json`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
        },
        method:"GET"
    });
}