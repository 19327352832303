import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';

const ModalService = (props) => {
    const services  = props.services ? props.services : [];
    return (
        <Dialog
            open={props.open}
            onClose={props.close}
        >
            <DialogTitle>
                Selecione o serviço
            </DialogTitle>
            <List>
                {services.map(service => 
                    <ListItem onClick={() => {props.selectService(service);props.close()}} button key={service.id}>
                        <ListItemText primary={service.service_name} />
                    </ListItem>
                )}
            </List>
        </Dialog>
    )
}

export default ModalService;