
import { base_url } from "./Base";

export const getFixedReserves = async (scheduleid) => {
    return await fetch(`${base_url}schedule/${scheduleid}/fixed_reserve`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"GET"
    });
}

export const deleteReserve = async (scheduleid,id) => {
    return await fetch(`${base_url}schedule/${scheduleid}/fixed_reserve/${id}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"DELETE"
    })
}