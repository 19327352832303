import React, { Component } from 'react';
import Header from '../Header/Header';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import { verify_token, complete_register } from '../../Auth/Auth';
import { Validator } from '../../Helpers/Validator';
import { Redirect } from 'react-router-dom';

class CompleteRegister extends Component {

    state = {
        invalidToken: false,
        current_password: '',
        password: '',
        password_confirmation: '',
        status_code: '',
        error: ''
    }

    constructor(props) {
        super();
        this.validator = new Validator();
    }

    saveState = (name) => (e) => {
        let value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    async componentDidMount() {
        let { token } = this.props.match.params;
        let valid_token = await verify_token(token);

        if(valid_token.status === 401) this.setState({invalidToken: true});
    }

    register = async (e) => {
        e.preventDefault();
        let { token } = this.props.match.params;
        let { password, password_confirmation, current_password } = this.state;

        const response = await complete_register(token, {password, password_confirmation, current_password});
        const status_code = response.status;
        const { error } = await response.json(); 
        this.setState({
            status_code, error
        })
    }

    render() {
        if(this.state.status_code === 200) return <Redirect to="/" />
        this.validator.setValues([
            { value: this.state.current_password, required: true, min: 7},
            { value: this.state.password, required: true, min: 7},
            { value: this.state.password_confirmation, equals: this.state.password },
        ]);
        if(this.state.invalidToken) {
            return (
                <Paper elevation={1} style={{margin:10, padding:10}}>
                    <Typography variant="caption">Token inválido</Typography>
                </Paper>
            )
        }
        return (
           <form onSubmit={this.register} >
            <Header title="Registrar"  />
            <Paper elevation={1} style={{margin: 10, padding: 10}} >
                <Typography style={{marginTop: 10, marginBottom: 10}} variant="caption">Complete o registro informando uma nova senha.</Typography>

                <TextField 
                    type="password"
                    label="Senha Atual"
                    fullWidth
                    onChange={this.saveState('current_password')}
                    error={this.state.status_code === 401}
                    helperText={this.state.error}
                 />
                <TextField 
                    type="password"
                    label="Senha"
                    fullWidth
                    onChange={this.saveState('password')}
                 />
                 <TextField 
                    type="password"
                    label="Confirme senha"
                    onChange={this.saveState('password_confirmation')}
                    fullWidth
                 />
                <Button disabled={!this.validator.check()} type="submit" style={{marginTop: "1em"}} fullWidth variant="contained" size="medium" color="secondary">
                    enviar
                </Button>
            </Paper>
          
           </form>
        )
    }
};

export default CompleteRegister;