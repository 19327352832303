import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

class Item extends Component {

    change = (schedule,active) => () => {
        this.props.toggle(schedule,active);
    }
    
    render() {
        const { interval, selected, standard } = this.props;

        return (
            <Typography 
            style={interval.active ? selected : standard} 
            onClick={this.change(interval.current,interval.active)} >
                {interval.current.slice(0,5)}
            </Typography>
        ) 
    }
}

export default class Schedule extends Component {
  
  changeSchedule = async (schedule,active) => {
    this.props.toggle(schedule,active);
  }

  render() {
      let { schedules, selected,standard } = this.props;
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} style={{padding:30}}>
                {schedules.map((schedule,index) => {
                    return <Item toggle={this.changeSchedule} 
                                 interval={schedule}
                                 key={index}
                                 selected={selected}
                                 standard={standard}
                            />
                })}
                
            </Grid>
        </Grid>
    )
  }
}
