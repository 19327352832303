import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import ListFixedReserves from '../FixedReserves/ListFixedReserves';
import { getFixedReserves, deleteReserve } from '../../Services/FixedReserves';
import Header from '../Header/Header';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';


const ConfirmReserve = (props) => {
    const confirm = (feedback) => () => {
        if(feedback) {
            props.del(feedback, props.reserveId);
        }
        props.close();
    }
    
    return (
        <Dialog open={props.open} onClose={props.close}>
            <DialogTitle>Deletar Reserva</DialogTitle>
            <DialogContent>
                <Typography variant="caption">
                    Deseja continuar? Essa ação irá deletar o agendamento automático
                    de horários fixos. (Os agendamentos realizados não serão afetados).
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={confirm(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={confirm(true)} color="primary">
                    Deletar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default class FixedReserves extends Component {
  
  state = {
      currentId: null,
      confirmDelete: false,
      reserves: [],
  }

  async componentDidMount() {
    let scheduleid = this.props.match.params.id;
    let response = await getFixedReserves(scheduleid);
    let reserves = await response.json();

    this.setState({
        reserves
    })
  }

  closeConfirm = () => {
    this.setState({
        currentId: null,
        confirmDelete:false
    })
  }

   confirm = (currentId) => {
    this.setState({
        confirmDelete: true,
        currentId
    })

  }

  delete = async (feedback, id) => {
    if(feedback) {
        let scheduleid = this.props.match.params.id;
        let response = await deleteReserve(scheduleid,id);
        if(response.status === 200) {
            let { reserves } = this.state;
            this.setState({
                reserves: reserves.filter(reserve => {
                    return reserve.id === id ? false : reserve
                })
            })
        }
    }
  }

  render() {
      if(hasPermission()) return <Redirect to="/" />

    //   let currentReserve = this.state.reserves.filter(reserve => reserve.id === this.state.currentId)[0];
    return (
        <div>
            <Header title="Horários Fixos" backbutton={this.props.history} />
            <ListFixedReserves 
                deleteReserve={this.confirm} 
                reserves={this.state.reserves} 
            />
            <ConfirmReserve 
             del={this.delete}
             reserveId={this.state.currentId}
             open={this.state.confirmDelete} 
             close={this.closeConfirm}
            />
        </div>
    )
  }
}
