import React, { Component } from 'react';
import { ScheduleServices } from '../ScheduleConfig/Edit/ScheduleServices';
import Header from '../Header/Header';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class Services extends Component {
  
  state = {
      open: false
  }

  toggleModal = () => {
      this.setState({
          open: !this.state.open
      })
  }

  render() {
    if(hasPermission()) return <Redirect to="/" />
    const header_buttons = [
        {type: "action", icon_name: "add", callback: this.toggleModal}
    ]
    return (
        <div>
            <Header buttons={header_buttons} title="Serviços" backbutton={this.props.history} />
            <ScheduleServices modal={this.toggleModal} open={this.state.open} {...this.props} />
        </div>
    )
  }
}
