import React, { Component } from 'react';
import Header from '../Header/Header';
import Schedule from '../Schedule/Schedule';
import { hasPermission } from '../../Auth/Auth';

export default class Home extends Component {

  render() {
      let header_buttons = [
          {
              type: "menu", items: [
                {title: "Agendas", link: "/schedule"},
                {title: "Configurações", link: "/settings"},
                {title: "Perfil", link: "/profile"},
                {title: "Sair", link: "/loggout"}
              ]
        }
      ]
      if(hasPermission()) {
        header_buttons[0].items.splice(0,2);
      } 
    return (
      <div>
          <Header buttons={header_buttons} title="Minha agenda"  />
          <Schedule />
      </div>
    )
  }
}
