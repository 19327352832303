import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';

export default class HeaderButtons extends Component {
    render() {
        return(
            <>
            {this.props.buttons.map((button, index) => {
                if(button.type === 'link') {
                    return (
                        <IconButton key={index} component={Link} to={button.link} >
                            <Icon style={{color:"#fff"}} >
                                {button.icon_name}
                            </Icon>
                        </IconButton>
                    )
                }

                if(button.type === 'action') {
                    return (
                        <IconButton key={index} onClick={button.callback} >
                            <Icon style={{color:"#fff"}} >
                                {button.icon_name}
                            </Icon>
                        </IconButton>
                    )
                }

                if(button.type === 'menu') {
                    return (
                        <HeaderMenu key={index} items={button.items} />
                    )
                }

                return null;
            })}
            </>
        )
    }
}