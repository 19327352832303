import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { getUsersFromCompany, send_register } from '../../Services/CompanyUsers';

const EditUser = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                 <TextField 
                    type="text"
                    label="Email"
                    fullWidth
                    defaultValue={props.data.email}
                    disabled
                 />
                
            </Grid>
        </Grid>
    )
}

const CreateUser = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.closeModal}
            aria-labelledby="form-dialog-title"
            fullScreen
        >
            <DialogTitle id="form-dialog-title">Novo usuário</DialogTitle>
            <DialogContent>
                 <TextField 
                    type="text"
                    label="Email"
                    fullWidth
                    onChange={props.save('email')}
                 />
                 <TextField 
                    type="text"
                    label="Nome"
                    fullWidth
                    onChange={props.save('name')}
                 />
                 <Typography variant="caption">
                    Preencha o email do novo usuário e enviaremos um formulário para 
                    se cadastrar.
                 </Typography>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.closeModal}  color="primary">
                Cancel
            </Button>
            <Button onClick={props.send}  color="primary">
                Enviar
            </Button>
            </DialogActions>
      </Dialog>
    )
}

class Users extends Component {

    state = {
        users: [],
        email: '',
        name: ''
    }

    async componentDidMount() {
        let { id } = JSON.parse(localStorage.getItem('current_company'));
        let resṕonse_users = await getUsersFromCompany(id);
        let users          = await resṕonse_users.json();
        this.setState({
            users
        })
    }

    saveState = ( name, id ) => (e) => {
        let value = e.target.value;
    
        this.setState({
            users: this.state.users.map(user => {
                if(id === user.id) {
                    user[name] = value;
                }
                return user;
            })
        });
      }

    changeNewUser = (name) => (e) => {
        let value = e.target.value;

        this.setState(prevState => ({
            [name]: value
        }));
    }

    sendEmail = async () => {
        let users = this.state.users;
        let { id } = JSON.parse(localStorage.getItem('current_company'));
        let {email , name} = this.state;
        this.props.modal();
        const response_user = await send_register(id,{email, name});
       
        if (response_user.status === 200) {
            const user = await response_user.json();
            users.push(user[0]);
            this.setState({
                users
            });
        }
    }

    render() {
        return (
            <Grid item xs={12} style={{margin:10}}>
                 {this.state.users.map((user, index) => 
                    <ExpansionPanel key={user.id} >
                        <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                            <Typography >{user.name}
                            <Typography variant="caption"> {!user.email_verified_at ? 'Cadastro pendente, enviado por email...' : ''}</Typography>
                            
                            </Typography>
                        </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <EditUser save={this.saveState} data={user} />
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                <CreateUser send={this.sendEmail} save={this.changeNewUser} open={this.props.open} closeModal={this.props.modal} />
            </Grid>
        )
    }
}

export default Users;
