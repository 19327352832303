import React from 'react';
import { Grid } from '@material-ui/core';
import FixedReservedCard from './FixedReservesCard';

const ListFixedReserves = (props) => {
        let del = (id) => {
            props.deleteReserve(id);
        }

        return(
            <Grid item xs={12} style={{margin: 10}}>
               {props.reserves.map((item, key) => (<FixedReservedCard del={del} key={key} reserve={item} />))}
            </Grid>
        )
}

export default ListFixedReserves;