import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import ImageCompressor from 'image-compressor.js';

const UploadThumb = (props) => {
    let compress = (e) => {
        const file = e.target.files[0];

        if (!file) {
        return;
        }
    
        new ImageCompressor(file, {
        quality: .7,
        success:(result) => {
            var f = new FileReader();
            f.onload = (e) => {
                props.changeImage(e.target.result);
            }

            f.readAsDataURL(result);
        
        },
        error(e) {
            console.log(e.message);
        },
        });
    }
    return (
        <>
         <Grid item xs={4} style={{paddingLeft: 10}} >
                <Avatar src={props.logo} alt="Thumb" style={{ width: 100, height: 100}}>
                    {props.logo ? props.logo : 'S'}
                </Avatar>
            </Grid>
            <Grid item xs={8}
                style={{ display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',}}
            >
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={compress}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" size="medium" color="secondary" component="span" >
                        Carregar Imagem
                    </Button>
                </label> 
            </Grid>
        </>
    )
}

export default UploadThumb;