import { base_url } from "./Base";

export const getUsersFromCompany = async (company_id) => {

    return await fetch(`${base_url}company/${company_id}/company_user`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"GET"
    });
}

export const send_register = async (companyid, body) => {
    console.log(JSON.stringify(body));
    return await fetch(`${base_url}company/${companyid}/company_user` ,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method:"POST",
        body: JSON.stringify(body)
    })
}