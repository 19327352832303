import React, { Component } from 'react';
import Header from '../Header/Header';
import Establishments from '../Settings/Establishments';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class Establishment extends Component {
  state = {
      open: false
  }

  toggleModal = () => {
    this.setState({
        open: !this.state.open
    })
  }

  render() {
    if(hasPermission()) return <Redirect to="/" />

    const header_buttons = [
        {type: "action", icon_name: "add", callback: this.toggleModal }
    ]
    
    return (
        <>
            <Header buttons={header_buttons} title="Estabelecimentos" backbutton={this.props.history} />
            <Establishments modal={this.toggleModal} open={this.state.open} />
        </>
    );
  }
}
