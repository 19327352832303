import React, { Component } from 'react';
import ProfileForm from '../ProfileForm/ProfileForm';
import Header from '../Header/Header';

export default class Profile extends Component {
  render() {
    const header_buttons = [
        { type: "menu", items: 
            [
                {title: "Alterar Senha", link: "/password"}
            ] 
        }
    ];
    return (
        <>
            <Header buttons={header_buttons} title="Perfil" backbutton={this.props.history} />
            <ProfileForm />
        </>
    );
  }
}
