import { base_url } from "./Base";

export const getUnlock = (scheduleid,date) => {
    return fetch(`${base_url}schedule/${scheduleid}/unlock_calendar?date=${date}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    })
}

export const createUnlock = (scheduleid,body) => {
    return fetch(`${base_url}schedule/${scheduleid}/unlock_calendar`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "POST",
        body: JSON.stringify(body)
    })
}

export const deleteUnlock = (scheduleid,time ,body) => {
    return fetch(`${base_url}schedule/${scheduleid}/unlock_calendar/${time}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "DELETE",
        body: JSON.stringify(body)
    })
}