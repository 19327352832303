import React, { Component } from 'react';
import Company from '../Settings/Company';
import Header from '../Header/Header';
import { Grid } from '@material-ui/core';
import { hasPermission } from '../../Auth/Auth';
import { Redirect } from 'react-router-dom';

export default class Settings extends Component {
  render() {
    if(hasPermission()) return <Redirect to="/" />
    const header_buttons = [
      {
          type: "menu", items: [
            {title: "Usuários", link: "/company_users"},
            {title: "Estabelecimentos", link: "/establishments"},
          ]
    }
  ]
    return (
      <Grid>
          <Header buttons={header_buttons} title="Configurações" backbutton={this.props.history} />
          <Company />
      </Grid>
    )
  }
}
