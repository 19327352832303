import React, { PureComponent } from 'react';
import CalendarModal from '../CalendarModal/CalendarModal';
import { free_schedule, workspace } from '../../Services/Schedule';
import {format} from 'date-fns';
import ListSchedule from '../ListSchedule/ListSchedule';
import ScheduleItem from '../ListSchedule/ScheduleItem';
import { get_scheduling_items, cancel_schedule } from '../../Services/SchedulingItems';
import { Grid, Typography, Icon, Paper } from '@material-ui/core';
import ModalSchedule from './ModalSchedule';
import ModalService from './ModalService';
import ModalEstablishment from './ModalEstablishment';
import ModalEdit from './ModalEdit';
import { CustomSnackbar } from '../Snackbars/CustomSnackbar';
import Loading from '../Loading/Loading';


export default class Schedule extends PureComponent {

    state = {
        schedule: [],
        reserved: [],
        services: [],
        selectedDate: "",
        selectedSchedule: "",
        selectedEdit: "",
        filter: true,
        filterService: {},
        openModalSchedule: false,
        openModalService: false,
        openModalEstablishment: false,
        openModalEdit: false,
        establishments: [],
        establishment_schedule: {},
        selectedEstablishment: "",
        worker: {},
        snackOpen: false,
        snackMessage: '',
        snackColor: '',
        isLoading: true
    }

    async componentDidMount() {
        let worker = {};
        let establishments = [];
        let response_workspace = await workspace();

        if(response_workspace.status === 200) {
            establishments = await response_workspace.json();
        }
        let establishment_schedule = establishments;
        let selectedEstablishment = establishments[0];
        if(selectedEstablishment) {
            let date = format(new Date(), 'yyyy-MM-dd');
            let services = selectedEstablishment.services;
            let filterService = services[0];
            if(filterService) {
                let response_free_schedule = await free_schedule(selectedEstablishment.schedule_id,date, filterService.id);
                let schedule = await response_free_schedule.json();
                if(response_free_schedule.status !== 401) {
        
                    this.setState({
                        schedule: schedule.schedule_free,
                        services,
                        filterService,
                        selectedDate:date,
                        establishments,
                        selectedEstablishment,
                        establishment_schedule,
                        worker
                    })
                } 
            }
        }
        this.setState({
            isLoading: false
        })
    }

    infoPaper = () => {
        return (
            <Paper elevation={1} style={{margin:10, padding: 10}}>
                <Typography variant="subtitle1">
                    Nenhuma agenda encontrada...
                </Typography>
                <Typography variant="caption">
                    - Verifique se existe uma agenda relacionada ao seu usuário.
                </Typography>
                <Typography variant="caption">
                    - Verifique se existe um serviço relacionado em sua agenda.
                </Typography>
            </Paper>
        )
    }

    getDate = async (selectedDate) => {
        let schedule_id =  this.state.selectedEstablishment.schedule_id;
        let date = format(selectedDate, 'yyyy-MM-dd');
        

        let response = await free_schedule(schedule_id,date,this.state.filterService.id);
        let schedule = await response.json();

        let response_reserved = await get_scheduling_items(schedule_id,date);
        let reserved = await response_reserved.json();
        this.setState({
            schedule: schedule.schedule_free,
            reserved,
            selectedDate:date
        });
    }

    changeFilter = async (selectedDate) => {
        let schedule_id =  this.state.selectedEstablishment.schedule_id;
        let date = format(selectedDate, 'yyyy-MM-dd');
      
        if(this.state.filter) {
            let response = await get_scheduling_items(schedule_id,date);
            let reserved = await response.json();
            this.setState({
                filter: !this.state.filter,
                reserved
            })
        } else {
        
            let response = await free_schedule(schedule_id,date,this.state.filterService.id);
            let schedule = await response.json();
            this.setState({
                filter: !this.state.filter,
                schedule: schedule.schedule_free,
            })
        }
    }

  schedule = (selectedSchedule) => () => {
      this.setState({
          openModalSchedule: true,
          selectedSchedule
      })
  
  }

  filterService = () => {
      this.setState({
        openModalService: !this.state.openModalService
      })
  }

  filterEstablishment = () => {
    this.setState({
        openModalEstablishment: !this.state.openModalEstablishment
    })
}

  closeModalSchedule = async (res) => {
    let schedule_id =  this.state.selectedEstablishment.schedule_id;
    let response = await free_schedule(schedule_id,this.state.selectedDate,this.state.filterService.id);
    let schedule = await response.json();
    let snackColor = '';
    let snackMessage = res.data || '';
    let snackOpen = true;
    if(res.status === 201) {
        snackColor = '#43A047';
    } else if(res.status) {
        snackColor = '#D32F2F';
    }

    this.setState({
        openModalSchedule: !this.state.openModalSchedule,
        selectedSchedule: "",
        schedule: schedule.schedule_free,
        snackColor,
        snackMessage,
    })

    if (snackMessage) this.setState({snackOpen});
  
  }

  closeModalService = () => {
      this.setState({
        openModalService: !this.state.openModalService
      })
  }

  closeModalEstablishment = () => {
    this.setState({
      openModalEstablishment: !this.state.openModalEstablishment
    })
}

  closeModalEdit = () => {
      this.setState({
          openModalEdit: !this.state.openModalEdit
      })
  }
  closeSnack = () => {
      this.setState({
          snackOpen: !this.state.snackOpen
      })
  }

  selectService = async (filterService) => {
    let schedule_id =  this.state.selectedEstablishment.schedule_id;
        
    let response_free_schedule = await free_schedule(schedule_id,this.state.selectedDate, filterService.id);
    let schedule = await response_free_schedule.json();
    this.setState({
        filterService,
        schedule: schedule.schedule_free,
    })

  }

  selectEstablishment = async (filterEstablishment) => {
      let selectedEstablishment = filterEstablishment;
      let establishment_schedule = this.state.establishments.find(establishment => filterEstablishment.schedule_id === establishment.schedule_id);
      let schedule_id = selectedEstablishment.schedule_id;

      let services = establishment_schedule.services;
      let filterService = services[0];
      let response_free_schedule = await free_schedule(schedule_id, this.state.selectedDate,filterService.id);
      let schedule = await response_free_schedule.json();

      let response_reserved = await get_scheduling_items(schedule_id,this.state.selectedDate);
      let reserved = await response_reserved.json();
      this.setState({
        selectedEstablishment,
        schedule: schedule.schedule_free,
        reserved,
        establishment_schedule,
        filterService,
        services
      })
  }

  edit = (schedule) => () => {
    if (schedule.id_user === null) {
        this.setState({
            openModalEdit: true,
            selectedEdit: schedule
        })
    }
  }

  cancelSchedule = async () => {
      const response = await cancel_schedule(this.state.selectedEdit.id);

      if (response.status === 200) {
          this.closeModalEdit();
          this.changeFilter(this.state.selectedDate);
      }
  }

  render() {
      if(this.state.isLoading) return <Loading />;
      if(!Object.keys(this.state.filterService).length ||
         !Object.keys(this.state.establishment_schedule).length) {
          return this.infoPaper();

      } 
    const { schedule, reserved } = this.state;
    const list = this.state.filter ? (
        <ListSchedule>
            {schedule.map((sched,index) => 
                <ScheduleItem onClick={this.schedule(sched)} key={index} label_info={sched} label_detail="Horário Livre" />
            )}
        </ListSchedule>
    ) : (
        <ListSchedule>
            {reserved.map((reserved,index) => 
                <ScheduleItem onClick={this.edit(reserved)} signal_color="#01bf9b" signal={reserved.id_user === null} key={index} label_info={format(reserved.start,'HH:mm')} label_detail={reserved.name} />
            )}
        </ListSchedule>
    )
    let { company_name, name} = this.state.selectedEstablishment;
    name = name ? name : "";
    company_name = company_name ? company_name : "";
    return (
        <Grid>
            <Grid item xs={12}>
                <Typography onClick={this.filterEstablishment} variant="caption" style={{color:"#000",display: "flex", alignItems: "center", margin:10}}>
                {`${company_name} - ${name}`} <Icon fontSize="small">keyboard_arrow_down</Icon>
                </Typography>
            </Grid>
            <CalendarModal 
                name={this.state.filterService.service_name} 
                filterService={this.filterService} 
                toggleFilter={this.changeFilter} 
                filter={this.state.filter} 
                export={this.getDate} />
            <Typography  
                style={{color:"#000",display: "flex", alignItems: "center", margin:10}} 
                variant="caption">
                Horários {this.state.filter ? 'Disponíveis' : 'Reservados'}: {this.state.filter ? schedule.length : reserved.length}
            </Typography>
            {list}
            <ModalSchedule 
            service={this.state.filterService} 
            close={this.closeModalSchedule} 
            open={this.state.openModalSchedule} 
            date={this.state.selectedDate}
            schedule={this.state.selectedSchedule}
            establishmentSchedule={this.state.selectedEstablishment}
            />
            <ModalService 
            selectService={this.selectService}
            open={this.state.openModalService}
            close={this.closeModalService}
            services={this.state.services}/>
            <ModalEstablishment 
                selectEstablishment={this.selectEstablishment}
                open={this.state.openModalEstablishment}
                close={this.closeModalEstablishment}
                establishments={this.state.establishments}
            />
            <ModalEdit 
                open={this.state.openModalEdit}
                close={this.closeModalEdit}
                action={this.cancelSchedule}
                text="Deseja cancelar esse agendamento?"
            />
            <CustomSnackbar message={this.state.snackMessage} 
                            color={this.state.snackColor}
                            open={this.state.snackOpen} 
                            handleClose={this.closeSnack}
                            />
        </Grid>
    )
  }
}
