import React, { Component } from 'react';
import { TextField, Button } from '@material-ui/core';
import Header from '../Header/Header';
import { Validator } from '../../Helpers/Validator';
import { reset_password } from '../../Auth/Auth';

export default class ResetPassword extends Component {
    state = {
        password: '',
        password_confirmation: ''
    }

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    saveState = (name) => (e) => {
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    }

    send = async (e) => {
        e.preventDefault();
        const { token } = this.props.match.params;
        const { password, password_confirmation } = this.state;
        
        const response = await reset_password({password, password_confirmation, token});

        if (response.status === 200) {
            this.setState({
                password: '',
                password_confirmation: ''
            });
        }
    }

  render() {
    this.validator.setValues([
        { value: this.state.password, required: true, min: 7},
        { value: this.state.password_confirmation, equals: this.state.password },
    ])
    return (
        <>
            <Header title="Redefinir Senha" backbutton={this.props.history} />
            <form onSubmit={this.send} style={{padding: 30}}>
                <TextField
                    id="password"
                    type="password"
                    label="Nova Senha"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    onChange={this.saveState('password')}
                />

                <TextField
                    id="confirmation_password"
                    type="password"
                    label="Confirmar Nova Senha"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    onChange={this.saveState('password_confirmation')}
                />

                <Button disabled={!this.validator.check()} style={{marginTop: "20px"}} type="submit" fullWidth variant="contained" size="medium" color="secondary">
                    Enviar
                </Button>
            </form>
        </>
    );
  }
}
