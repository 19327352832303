import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';

export default class Bar extends Component {
  
  render() {
 
    return (
        <AppBar position="static" style={{background:"#01BF9B",flexGrow: 1}}>
              {this.props.children}
        </AppBar>
    )
  }
}
