import React from 'react';
import { base_url } from "../Services/Base";
import { Redirect } from 'react-router-dom';

export const hasPermission = () => {
    let permission = JSON.parse(localStorage.getItem('current_company'));
    if(permission.pivot.type_user !== 'm') {
      return true;
    }
}

export const loggout = () => {
    let items = [
        "worker_app_token",
        "current_user",
        "company_created",
        "current_company",
        "company_type"
    ]
    items.forEach((item) => {
        localStorage.removeItem(item);
    })

    return  <Redirect to={{ pathname: '/worker'}}/>
    
}

export const isAuthenticated  = () => {
    if(localStorage.getItem('worker_app_token')) return true;
    return false;
}

export const companyCreated = () => {
    if(localStorage.getItem('company_created')) return true;
    return false;
}

export const verify_token = (token) => {
    return fetch(`${base_url}company_user/verify_token/${token}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json"         
        },
        method: "GET"
    })
}

export const login = async (credentials) => {
    let response = await fetch(`${base_url}company_user/login`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json"         
        },
        method: "POST",
        body: JSON.stringify({email: credentials.email, password: credentials.password})
    });

    if(response.status === 200) {
        let body = await response.json();
        console.log(body);
        localStorage.setItem("worker_app_token", body.credentials.token_type +" " + body.credentials.access_token);
        return {
            status: response.status,
            user: body.user,
            company: body.company,
            type: body.type
        };
    }

    return {
        status: response.status,
        data: await response.json()
    }
}

export const register = (data) => {
    return fetch(`${base_url}company_user/register`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json"         
        },
        method: "POST",
        body: JSON.stringify({
            email: data.email, 
            name: data.name,
            birthdate: data.formated_birthdate,
            telephone: data.cellphone,
            sex: data.sex,
            picture: data.picture,
            password: data.password,
            password_confirmation: data.password_confirmation,
        })
    })
    
}

export const complete_register = (token, body) => {
    return fetch(`${base_url}company_user/complete_register/${token}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")        
        },
        method: "POST",
        body: JSON.stringify(body)
    })
}

export const password = (data) => {
    return fetch(`${base_url}company_user/password`,{
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")        
        },
        method: "POST",
        body: JSON.stringify({
           current_password: data.current_password,
           new_password: data.new_password,
           new_password_confirmation: data.new_password_confirmation
        })
    })
    
}

export const authUser = () => {
    return fetch(`${base_url}worker`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")        
        },
        method: "GET",
    })
}

export const authUserEstablishments = (workerid) => {
    return fetch(`${base_url}worker/${workerid}/establishment`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")        
        },
        method: "GET",
    })
}

export const exist = (data) => {
    return fetch(`${base_url}company_user/exist`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem("token")
        },
        method: "POST",
        body: JSON.stringify({
            email: data.email
        })
    })
}

export const verify = (token) => {
    return fetch(`${base_url}company_user/verify/${token}`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "GET"
    })
}

export const login_google = (accessToken) => {
    return fetch(`${base_url}company_user/google`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ accessToken })
    })
}

export const forgot_password = (data) => {
    return fetch(`${base_url}company_user/forgot_password`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data)
    })
}

export const reset_password = (data) => {
    return fetch(`${base_url}company_user/reset_password`, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data)
    })
}






 