import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export const CustomSnackbar = (props) => {
    
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={props.open}
                onClose={props.handleClose}
                autoHideDuration={3000}

            >
                <SnackbarContent
                    style={{background: props.color}}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" >
                        {props.message}
                        </span>
                    }
                />
            </Snackbar>
        </div>
    )
}