import React, { Component } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { TimeMask }  from '../../Helpers/RegexMasks';
import MaskTextField from '../MaskTextField/MaskTextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Validator } from '../../Helpers/Validator';



export class Title extends Component {

  render() {
    return(
    <Grid item xs={10}>
      <Typography variant="subheading">
        {this.props.title}
      </Typography>
    </Grid>
    )
  }
}


export class ScheduleConfigInfo extends Component {

  constructor() {
    super();
    this.validator = new Validator();
  }

  maskIntervalCallback = () => {
    return TimeMask(this.props.interval);
  }

  maskNotificationCallback = () => {
    return TimeMask(this.props.notification);
  }


  changeData = (name) => (e) => {
    this.props.export(e.target.value,name);
  }


  render() {
    return (
      <Paper 
        style={{
          padding: 10,
          marginBottom:10
        }}
      
      > 
       <Title  title="Configurações da agenda"/>
       <Grid  item xs={12} >
          <FormControl style={{margin:"0",fullWidth:true,display:"flex", wrap:"nowrap"}}>
            <InputLabel htmlFor="establishment">Estabelecimento</InputLabel> 
            <Select
                value={this.props.establishment}
                onChange={this.changeData('establishment')}
                inputProps={{
                    name: "Estabelecimento",
                    id: "establishment",
                }}
                >
                {this.props.establishments.map(establishment => 
                  <MenuItem key={establishment.id} value={establishment.id}>{establishment.name}</MenuItem>
                )}

            </Select>
        </FormControl>
        </Grid>
       <Grid  item xs={12} >
          <FormControl style={{margin:"0",fullWidth:true,display:"flex", wrap:"nowrap"}}>
            <InputLabel htmlFor="worker">Responsável da agenda</InputLabel> 
            <Select
                value={this.props.worker}
                onChange={this.changeData('worker')}
                inputProps={{
                    name: "Responsável da agenda",
                    id: "worker",
                }}
                >
                {this.props.workers.map(worker => 
                  <MenuItem key={worker.pivot.id} value={worker.pivot.id}>{worker.name}</MenuItem>
                )}

            </Select>
        </FormControl>
        </Grid>
        <Grid item xs={6}>
          <MaskTextField 
            label="Intervalo agenda"
            mask={this.maskIntervalCallback}
            show={false}
            onChange={this.changeData('interval')}
            defaultValue={this.props.interval}
          />
        </Grid>
        <Grid item xs={6}>
          <MaskTextField 
            label="Tempo notificação"
            mask={this.maskNotificationCallback}
            show={false}
            onChange={this.changeData('notification')}
            defaultValue={this.props.notification}
          />
        </Grid>
       
      </Paper>
    )
  }
}
