import React, { Component } from 'react';
import {ScheduleConfigInfo} from '../ScheduleConfigInfo';
import ScheduleConfigDays from '../ScheduleConfigDays';
import { Button } from '@material-ui/core';
import {Validator} from '../../../Helpers/Validator';
import { getUsersFromCompany } from '../../../Services/CompanyUsers'; 
import { create_schedule } from '../../../Services/ScheduleConfig';
import { createWorkingTimes } from '../../../Services/WorkingTime';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { getEstablishmentsFromCompany } from '../../../Services/Establishment';

const DEFAULT_DAYS = [
    {
        value: "1", label: "Segunda-feira", checked: false,
        working: []
    },
    {
        value: "2", label: "Terça-feira", checked: false,
        working: []
    },
    {
        value: "3", label: "Quarta-feira", checked: false,
        working: []

    },
    {
        value: "4", label: "Quinta-feira", checked: false,
        working: []

    },
    {
        value: "5", label: "Sexta-feira", checked: false,
        working: []
    },
    {
        value: "6", label: "Sábado", checked: false,
        working: []
        
    },
    {
        value: "0", label: "Domingo", checked: false,
        working: []

    },
]

export class ScheduleAdd extends Component {

    constructor(props) {

        super();
        this.state = {
            interval: "",
            notification: "",
            worker: "",
            workers: [],
            establishment: '',
            establishments: [],
            days: DEFAULT_DAYS,
            message: "",
            alertColor: "",
            alert: false

        }

        this.validator = new Validator();
        this.form = '';

    }

    async componentDidMount() {

        let defaultValue = this.state.days.map(({value,label,checked}) =>
           ({value,label,checked,working: [
               { start:"08:00:00", end:"12:00:00"},
               { start:"13:00:00", end:"18:00:00"}
            ]})
        );
        this.setState({
            days: defaultValue
        })
        let company = JSON.parse(localStorage.getItem('current_company'));
        let response_workers = await getUsersFromCompany(company.id);
        let workers = await response_workers.json();
        let response_establishments = await  getEstablishmentsFromCompany(company.id);
        let establishments = await response_establishments.json();

        this.setState({
            workers,
            establishments
        });


    }


    getInfo = (data,name) => {
        let value = data;
        this.setState({
            [name]:value.toString()
        })

    }


    getDays = (data) => {
        this.setState(prevState => ({
            days: prevState.days.map(
            day => (day.value === data.value ? Object.assign(data) : day)
          )
        }));
    }

    createSchedule = (e) => {
        e.preventDefault();
        let { worker, interval, notification, establishment } = this.state;
        

        let working_times = [];
        
        this.state.days.map(day => {
            if(day.checked) {
                day.working.map(work => {
                  working_times =  working_times.concat( { 
                                        day_of_week: day.value, 
                                        start: work.start,
                                        end: work.end
                                    });
                 return '';
               })
            }
            return '';
        })

        create_schedule({worker, interval, notification, establishment }).then(res => {

            if(res.status === 201) {
                
                res
                .json()
                .then(data => {
                    this.form.reset();
                    this.setState({
                        worker: "",
                        establishment: "",
                        notification: "",
                        interval: "",
                        days: DEFAULT_DAYS,
                        message: data.success,
                        alert: true,
                        alertColor: "#41c300"
                    })
                    createWorkingTimes(data.id,working_times)
                    
                })
    
            } else if(res.status === 422){
               res.json()
                    .then(data => {
                        this.setState({
                            alert: true,
                            message: data.error,
                            alertColor: "#B00020"
                            
                        })
                    })
            } else {
                this.setState({
                    alert: true,
                    message: "Oops, algo deu errado!",
                    alertColor: "#B00020"
                    
                })
            }

        })
    }

    handleClose = () => {
        this.setState({
            alert: !this.state.alert
        })
    }

    render() {
        this.validator.setValues([
            {name: "interval", value:this.state.interval, min:5, isTime:true},
            {name: "notification", value:this.state.notification, min:5, isTime:true},
            {name: "worker", value:this.state.worker, required:true},
        ]);
        let validCheckbox = false;

        for(let i = 0;i < this.state.days.length;i++) {
            if(this.state.days[i].checked === true)
            {
                validCheckbox = true;
                break;
            }
        }

        let valid = this.validator.check();
        return (
           <div style={{margin:10}}>
            <form ref={(el) => this.form = el} onSubmit={this.createSchedule}>

                <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.alert}
                autoHideDuration={2500}
                onClose={this.handleClose}
                >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={this.state.message}
                    style={{backgroundColor: this.state.alertColor}}
                />
                </Snackbar>

                    <ScheduleConfigInfo 
                        interval={this.state.interval} 
                        notification={this.state.notification} 
                        worker={this.state.worker}
                        workers={this.state.workers}
                        export={this.getInfo}
                        establishments={this.state.establishments}
                        establishment={this.state.establishment}
                    />
                    <ScheduleConfigDays 
                        show={valid} 
                        export={this.getDays} 
                        days={this.state.days}
                    />


                    <Button
                        type="submit"
                        style={{marginTop: "1em"}}
                        fullWidth
                        variant="contained" size="medium" color="secondary"
                        disabled={!(valid && validCheckbox)}
                    >
                        Criar
                    </Button>
                </form>
           </div>
        )
    }
}