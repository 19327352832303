import { base_url } from "./Base";

export const getServices = async (scheduleid, query = '') => {
    return await fetch(`${base_url}schedule/${scheduleid}/service${query}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "GET"
    });
}

export const createServices = async (scheduleid, body) => {
    return await fetch(`${base_url}schedule/${scheduleid}/service`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "POST",
        body: JSON.stringify(body)
    })
}

export const updateService = async (scheduleid,serviceid, body) => {
    return await fetch(`${base_url}schedule/${scheduleid}/service/${serviceid}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "PUT",
        body: JSON.stringify(body)
    });
}

export const deleteService = async (scheduleid, serviceid) => {
    return await fetch(`${base_url}schedule/${scheduleid}/service/${serviceid}`, {
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json" ,
            "Authorization" : localStorage.getItem("worker_app_token")  
        },
        method: "DELETE",
    })
}