import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import WorkingTimeItem from './WorkingTimeItem';
import SelectInterval from '../../SelectIntervals/SelectInterval';
import Icon from '@material-ui/core/Icon';

import IconButton from '@material-ui/core/IconButton';
import { deleteWorkingTime, createWorkingTimes } from '../../../Services/WorkingTime';


export default class WorkingTimeList extends PureComponent {

    constructor(props) {
        super(props);

        let times = [ 
            "00:00","01:00","02:00","03:00","04:00","05:00",
            "06:00","07:00","08:00","09:00","10:00","11:00",
            "12:00","13:00","14:00","15:00","16:00","17:00",
            "18:00","19:00","20:00","21:00","22:00","23:00"
        ]

        this.state = {
            workings: props.working,
            start: "00:00",
            end: "00:00"
        }

        this.times = times;
    }
   
    getIntervals = (time) => {
        const { start, end} = time;
        this.setState({
            start,
            end
        })
    }

    deleteWorking = async (id) => {
        const { seid } = this.state.workings[0];
        let workings = this.state.workings.filter(working => {
            if(working.id !== id) return working;

            return null;
        })

        this.setState({
            workings
        })
        await deleteWorkingTime(seid, id);
    }

    add = async () => {
        const { day_of_week, seid } = this.props
        const { start, end} = this.state;
        let data = [
            {start, end, day_of_week}
        ]

        let response = await createWorkingTimes(seid, data);
        let json = await response.json();
        this.setState({
            workings: [...this.state.workings, ...json]
        });
    }

  render() {
    return (
        <Grid container>
            {this.state.workings.map(working => <WorkingTimeItem 
                                                    delete={this.deleteWorking} 
                                                    working={working} 
                                                    key={working.id} 
                                                />)}
            <SelectInterval change={this.getIntervals} 
                            start={this.state.start} 
                            end={this.state.end} 
                            times={this.times}>
          <IconButton color="primary"  onClick={this.add}>
            <Icon>add</Icon>
          </IconButton>
            </SelectInterval>
        </Grid>
    )
  }
}
